import { observable } from "mobx";
import User from "./User";
import Model from "./base/Model";
import Field from "./decorators/Field";

class Prompt extends Model {
  static modelName = "Prompt";

  @Field
  @observable
  collectionId: string | undefined;

  @Field
  @observable
  documentId: string | undefined;

  @observable
  prompt: string | undefined;

  @observable
  answer: string | undefined;

  @observable
  option: string | undefined;

  @observable
  user: User;

  @observable
  userId: string;
}

export default Prompt;
