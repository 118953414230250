import env from "~/env";

/**
 * True if the current installation is the cloud hosted version at getoutline.com
 */
const isCloudHosted = [
  "https://app.outline.dev:3000",
  "https://editor.edural.com",
].includes(env.URL);

export default isCloudHosted;
