import invariant from "invariant";
import { action, runInAction, computed } from "mobx";
import Prompt from "~/models/Prompt";
import { PaginationParams } from "~/types";
import { client } from "~/utils/ApiClient";
import RootStore from "./RootStore";
import Store, { PAGINATION_SYMBOL } from "./base/Store";

type FetchPageParams = PaginationParams & Record<string, any>;

export default class PromptsStore extends Store<Prompt> {
  constructor(rootStore: RootStore) {
    super(rootStore, Prompt);
  }

  @action
  fetchPaginated = async (params: FetchPageParams): Promise<Prompt[]> => {
    this.isFetching = true;

    try {
      const res = await client.post(`/prompts.list`, params);
      invariant(res?.data, "Data not available");

      let response: Prompt[] = [];
      runInAction(`PromptsStore#fetchPaginated`, () => {
        response = res.data
          .map((item: Prompt) => {
            item.userId = item.user.id;
            return item;
          })
          .map(this.add);
        this.isLoaded = true;
      });

      response[PAGINATION_SYMBOL] = res.pagination || {};
      return response;
    } finally {
      this.isFetching = false;
    }
  };

  @computed
  get orderedData(): Prompt[] {
    const prompts = Array.from(this.data.values());

    return prompts.sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1));
  }
}
